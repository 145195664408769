import React from "react"
import { Link } from "gatsby"
import { useState } from "react"
import { navigate } from "gatsby"
import burgerWhite from "../assets/images/burger-white.svg"
import Button from "./core/Button"

const MainNav = ({ menuLinks, path }) => {
  const [toggled, setToggle] = useState(false)
  const [hovered, setHover] = useState(false)

  // toggle main menu
  const triggerToggle = () => setToggle(!toggled)

  // change burger colour on hover
  const triggerHover = () => setHover(!hovered)

  // close main nav menu on hash navigation
  const closeMenu = () => window.innerWidth < 768 && setToggle(false)

  return (
    <nav className="site-header__nav">
      <button
        className={`site-header__nav__toggle ${toggled ? "toggled" : ""}`}
        onClick={triggerToggle}
      >
        <img
          onMouseEnter={triggerHover}
          onMouseLeave={triggerHover}
          src={burgerWhite}
          className="burger"
          aria-hidden="true"
          alt="burger-menu-icon"
        />
      </button>

      <ul
        className={`site-header__nav__list ${toggled ? "open" : "closed"}`}
        onClick={() => closeMenu()}
      >
        {menuLinks.map((link, i) => {
          if (link.link.includes("https://")) {
            return (
              <li key={link.name} className="site-header__nav__list-item">
                <a className="site-header__nav__link" href={link.link}>
                  {link.name}
                </a>
              </li>
            )
          } else {
            return (
              <li key={link.name} className="site-header__nav__list-item">
                <Link className="site-header__nav__link" to={link.link}>
                  {link.name}
                </Link>
              </li>
            )
          }
        })}
        <li className="site-header__nav__list-item">
          <Button
            className="site-header__nav__link"
            colour="dark"
            text="Share your story"
            type="link"
            link="/#your-story"
            gatsbyLink={false}
            padding={"wide"}
            onClick={() => {
              navigate("/")
              window.applyLabels(true)
            }}
          />
        </li>
      </ul>
    </nav>
  )
}

export default MainNav
