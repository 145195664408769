import React from "react"
import Img from "gatsby-image/withIEPolyfill"

export default ({ className, data, alt, objectFit="cover" }) => (
  <div className={className}>
    <Img 
      fluid={data} 
      objectFit={objectFit}
      objectPosition="50% 50%"
      style={{width: '100%', height: '100%'}}
      alt={alt}
    />
  </div>
)