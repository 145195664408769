/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import Layout from "./src/components/core/Layout"
import { navigate } from "gatsby"

// For page transition animation
export function wrapPageElement({ element, props }) {
  return <Layout {...props}>{element}</Layout>
}

// Promotigo form - added data attribute to form divs. Used to help position form elements.
window.applyLabels = (shouldNavigate = false) => {
  const interval = setInterval(() => {
    const form = document.querySelector("#promotigoForm form")
    if (form) {
      if (shouldNavigate) {
        navigate("#your-story")
      }
      const labels = Array.from(form.querySelectorAll("label")).map(label => {
        label
          .closest('[id^="divp"]')
          .setAttribute("data-label", label.textContent)
        return label
      })
      if (labels && labels.length) {
        clearInterval(interval)
      }
    }
  }, 1000)
}

window.applyLabels()

// Logic to control adding/removing home button logo on desktop
const myScrollFunc = function () {
  let y = window.scrollY
  let navHeight = document.getElementById("header").offsetHeight
  const logo = document.querySelector(".site-header__logo")
  let heroImageHeight = document.querySelector(".hero__image").offsetHeight
  if (y >= heroImageHeight - navHeight) {
    logo.classList.add("show")
  } else {
    logo.classList.remove("show")
  }
}

window.addEventListener("scroll", myScrollFunc)
