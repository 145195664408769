// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-f-q-js": () => import("./../../../src/pages/f&q.js" /* webpackChunkName: "component---src-pages-f-q-js" */),
  "component---src-pages-giveaways-js": () => import("./../../../src/pages/giveaways.js" /* webpackChunkName: "component---src-pages-giveaways-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/terms-and-conditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */)
}

