import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "./core/Image"

const Footer = ({ address, siteTitle }) => {
  const data = useStaticQuery(graphql`
    {
      reginaLogo: file(
        relativePath: { eq: "assets/images/regina-logo-vertical.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
      sofidelLogo: file(
        relativePath: { eq: "assets/images/sofidel-logo.png" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid_noBase64
          }
        }
      }
    }
  `)

  const { fluid: reginaLogo } = data.reginaLogo.childImageSharp
  const { fluid: sofidelLogo } = data.sofidelLogo.childImageSharp

  return (
    <footer className="container site-footer">
      <Image
        className="site-footer__regina-logo"
        alt={siteTitle}
        data={reginaLogo}
      />

      <div className="site-footer__info">
        <p className="site-footer__company">
          &copy; {new Date().getFullYear()} Soffass Spa - P.IVA 01829730462
        </p>

        <ul className="site-footer__nav">
          <li className="site-footer__nav-item">
            <a
              href="https://www.regina.uk.com/privacy-policy"
              className="site-footer__link"
            >
              Privacy Policy
            </a>
          </li>
          <li className="site-footer__nav-item">
            <a
              href="https://www.regina.uk.com/cookie-policy"
              className="site-footer__link"
            >
              Cookies Policy
            </a>
          </li>
          <li className="site-footer__nav-item">
            <a
              href="https://www.regina.uk.com/terms-and-conditions/"
              className="site-footer__link"
            >
              Terms and Conditions
            </a>
          </li>
          <li className="site-footer__nav-item">
            <a
              href="https://www.regina.uk.com/terms-of-use"
              className="site-footer__link"
            >
              Terms of Use
            </a>
          </li>
        </ul>
      </div>

      <Image
        className="site-footer__sofidel-logo"
        alt={siteTitle}
        data={sofidelLogo}
      />
    </footer>
  )
}

export default Footer
