import { Link } from "gatsby"
import React from "react"

const Button = ({ colour, text, link, type, onClick, gatsbyLink, padding }) => {
  const className = `button button-${colour} button-${padding}`

  return type === "link" ? (
    gatsbyLink ? (
      <Link data-text={text} className={className} to={link} onClick={onClick}>
        {text}
      </Link>
    ) : (
      <a
        href={link}
        onClick={onClick}
        title={text}
        className={className}
        data-text={text}
      >
        {text}
      </a>
    )
  ) : (
    <button onClick={onClick} data-text={text} className={className}>
      {text}
    </button>
  )
}

export default Button
